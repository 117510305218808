import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntDatePicker, TimePicker as AntTimePicker, TimePickerProps } from 'antd';
import { CalendarIcon } from 'assets/svg';

const DatePickerPanelContainer = styled.div`
  .ant-picker-content th,
  .ant-picker-content td {
    &::before {
      display: none;
    }
    > div {
      &:hover {
        background-color: var(--grey-light);
        color: var(--orange);
        border-radius: 6px;
      }
      padding-top: 2px;
      &.today {
        border-radius: 6px;
        border: 1px solid var(--orange);
      }
      &.disabled {
        color: var(--grey);
        background-color: var(--grey-light);
      }
      &.overlapped {
        color: var(--green-dark);
        background-color: var(--grey-light);
      }
      &.selected {
        border-radius: 6px;
        color: white;
        background-color: var(--orange);
      }
    }
  }
`;

type DatePickerWrapperProps = Omit<React.ComponentProps<typeof AntDatePicker>, 'size'> & {
  size?: any;
};

const DatePickerWrapper = styled(AntDatePicker)<DatePickerWrapperProps>`
  &.ant-picker-large .ant-picker-input > input {
    font-size: 16px;
    font-family: var(--font-family-regular);
    line-height: 16px !important;
  }
  border-radius: 23.5px;

  ${({ size }) => {
    if (size === 'default') {
      return `height:40px;`;
    }
  }}
`;

const TimePickerWrapper = styled(AntTimePicker)`
  .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }
  border-radius: 23.5px;
`;

const Legend = styled.span`
  padding: 4px 12px;
  &.today {
    border-radius: 6px;
    background-color: var(--orange);
    color: var(--white);
  }
  &.disabled {
    color: var(--grey);
    background-color: var(--grey-light);
  }
  &.overlapped {
    color: var(--green-dark);
    background-color: var(--grey);
  }
`;

type DatePickerProps = DatePickerWrapperProps & {
  overlappedFootnote?: string;
  disabledFootnote?: boolean;
  showTime?: boolean;
};

export default function DatePicker({ disabledDate, overlappedFootnote, disabledFootnote, ...props }: DatePickerProps) {
  return (
    <DatePickerWrapper
      disabledDate={disabledDate}
      suffixIcon={<CalendarIcon />}
      size="large"
      showToday
      panelRender={(node) => <DatePickerPanelContainer>{node}</DatePickerPanelContainer>}
      renderExtraFooter={() => {
        if (!overlappedFootnote && !disabledFootnote) {
          return null;
        }
        return (
          <div className="py-2">
            {overlappedFootnote && (
              <p className="m-0 text-xs">
                <Legend className="overlapped">1</Legend> {overlappedFootnote}
              </p>
            )}
            <p className="m-0 text-xs mt-2">
              <Legend className="disabled">5</Legend> Unavailable dates
            </p>
          </div>
        );
      }}
      {...props}
    />
  );
}

export function TimePicker(props: TimePickerProps) {
  return <TimePickerWrapper suffixIcon={<CalendarIcon />} {...props} size="large" />;
}
